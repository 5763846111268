import React from 'react';
import { SlIconButton } from '@shoelace-style/shoelace/dist/react';
import Logo from './Logo';
import Title from '../Title';

type HeaderProps = {
  onGear: () => void;
}

const Header = ({
  onGear,
}: HeaderProps) => (
  <div className="header u-background-color-primary">
    <Logo />
    <Title />
    <div className="button-wrapper">
      <SlIconButton
        className="gear-icon sl-theme-dark"
        name="gear"
        onClick={onGear}
      />
    </div>
  </div>
);

export default React.memo(Header);
