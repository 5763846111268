const subscribe = (eventName: string, listener: () => void) => {
  document.addEventListener(eventName, listener);
};

const unsubscribe = (eventName: string, listener: () => void) => {
  document.removeEventListener(eventName, listener);
};

const publish = (eventName: string, data?: Record<string, unknown>) => {
  const event = new CustomEvent(eventName, { detail: data });
  document.dispatchEvent(event);
};

export { publish, subscribe, unsubscribe };
