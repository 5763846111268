import { storage } from '../components/State/ConnectionContext';
import fetch from '../utils/fetchWithTimeout';
import { Goals } from '../utils/generatePresetLineGoals';

type WaypointsResponse = {
  data?: string;
  message?: string;
  status: string;
}

export type Goal = {
  lat: number;
  lon: number;
  yaw?: number;
}

const postWaypoints = async (
  goals: Goals,
  mode: 'PREEMPT' | 'APPEND'
) => {
  const baseUrl = storage.getBaseUrl()
  const bearerToken = storage.getBearerToken()
  const deviceKey = storage.getDeviceKey()
  if (!bearerToken || !deviceKey) {
    throw Error('Missing bearer token or device key');
  }
  const response = await fetch(`${baseUrl}/gps-waypoints?device_key=${deviceKey}`, {
    body: JSON.stringify({
      goals,
      navigation_options: {
        mode: mode == 'PREEMPT' ? 1 : 0
      }
    }),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  if (!response.ok) {
    throw Error('Bad response');
  }

  const json: WaypointsResponse = await response.json();

  if (json.status === 'success' && json.data) {
    return json.data;
  }

  throw Error(`Bad status - ${json.status} - ${json.message}`);
};

export default postWaypoints;
