import { Marker } from '../types/Marker';

const calculateYaw = (markerOne: Marker, markerTwo: Marker) => {
  const x = Math.cos(markerTwo.lat)
    * Math.sin(markerOne.lon - markerTwo.lon);
  const y = (
    Math.cos(markerOne.lat)
    * Math.sin(markerTwo.lat)
  ) - (
    Math.sin(markerOne.lat)
    * Math.cos(markerTwo.lat)
    * Math.cos(markerOne.lon - markerTwo.lon)
  );

  return Math.atan2(y, -x);
};

export default calculateYaw;
