import React, { useState, createContext, useContext, useEffect } from 'react'
import { ConnectionStatus } from '../../api/types'
import StoredInfo from '../../storage/StoredInfo';
import fetchUuid from '../../api/fetchUuid';

type ConnectActionType = (bearerToken: string, deviceKey: string) => void

const ConnectionStatusContext = createContext<ConnectionStatus>(ConnectionStatus.NotConnected)
const UuidContext = createContext<string>('')
const ConnectActionContext = createContext<ConnectActionType>(() => null)

export const getUuidContext = () => useContext(UuidContext)
export const getConnectionStatusContext = () => useContext(ConnectionStatusContext)
export const getConnectionActionContext = () => useContext(ConnectActionContext)

export const storage = new StoredInfo();

export const ConnectionContext: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [status, setStatus] = useState<ConnectionStatus>(ConnectionStatus.NotConnected)
  const [uuid, setUuid] = useState<string>('')

  // This attempts a UUID call and updates the credentials, uuid and status accordingly
  const connectAction: ConnectActionType = (bearerToken: string, deviceKey: string) => {
    setStatus(ConnectionStatus.Connecting)
    setUuid('')
    storage.setBearerToken(bearerToken)
    storage.setDeviceKey(deviceKey)
    fetchUuid().then(result => {
      setUuid(result.uuid ?? '')
      setStatus(!!result.uuid ? ConnectionStatus.Connected : ConnectionStatus.Failed)
    }).catch(error => {
      console.log("Failed when requesting uuid", error)
      setStatus(ConnectionStatus.Failed)
    })
  }

  useEffect(() => {
    connectAction(storage.getBearerToken(), storage.getDeviceKey())
  }, [])

  return (
    <ConnectionStatusContext.Provider value={status}>
      <UuidContext.Provider value={uuid}>
        <ConnectActionContext.Provider value={connectAction}>
          {children}
        </ConnectActionContext.Provider>
      </UuidContext.Provider>
    </ConnectionStatusContext.Provider>
  )
}

export default ConnectionContext