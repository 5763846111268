import { storage } from '../components/State/ConnectionContext';
import fetch from '../utils/fetchWithTimeout';

type MotionCommandResponse = {
  data?: {};
  message?: string;
  status: string;
}

const postMotionCommand = async (
  command: 'STOP' | 'RESUME' | 'PAUSE'
) => {
  const baseUrl = storage.getBaseUrl()
  const bearerToken = storage.getBearerToken()
  const deviceKey = storage.getDeviceKey()
  if (!bearerToken || !deviceKey) {
    throw Error('Missing bearer token or device key');
  }
  const response = await fetch(`${baseUrl}/motion-command?device_key=${deviceKey}`, {
    body: JSON.stringify({ motion_command: command }),
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  const json: MotionCommandResponse = await response.json();

  if (response.ok && json.status === 'success' && json.data) {
    return json.data;
  }

  console.log("postMotionCommand failed", json.data)
  throw Error(`postMotionCommand failed - ${json.status} - ${json.message}`);
};

export default postMotionCommand;
