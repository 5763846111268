import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { MapProvider } from 'react-map-gl';
import { ConnectionContext } from './State/ConnectionContext';
import Layout from './Layout';
import PolymathFeedbackContext from './State/PolymathFeedbackContext';

const queryClient = new QueryClient({});

const Application = (): JSX.Element => {
  return (
    <ConnectionContext>
      <PolymathFeedbackContext>
        <MapProvider>
          <QueryClientProvider client={queryClient}>
            <Layout />
          </QueryClientProvider>
        </MapProvider>
      </PolymathFeedbackContext>
    </ConnectionContext>
  );
};

export default React.memo(Application);
