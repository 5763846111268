import { GeoPolyline } from "../utils/GeoPolyline"

const boundary: GeoPolyline = [
  [-120.572103, 36.967472],
  [-120.562272, 36.967472],
  [-120.561659, 36.980734],
  [-120.571950, 36.981052],
  [-120.572103, 36.967472],  // first point to complete the loop
]

export default boundary