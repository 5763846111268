import React from 'react';
import { Layer, Source } from 'react-map-gl';
import { GeoPolyline } from '../../utils/GeoPolyline';

type BoundariesSourceProps = {
  boundaries: GeoPolyline;
};

const BoundariesSource = ({
  boundaries,
}: BoundariesSourceProps) => {
  const data: GeoJSON.FeatureCollection<GeoJSON.Geometry> = {
    features: [{
      geometry: {
        coordinates: [
          boundaries,
        ],
        type: 'Polygon',
      },
      properties: {},
      type: 'Feature',
    }],
    type: 'FeatureCollection',
  };

  return (
    <Source
      type="geojson"
      data={data}
    >
      <Layer
        paint={{
          'line-color': '#D1EE78',
          'line-width': 7,
          'line-offset': 0,
        }}
        type="line"
      />
      <Layer
        paint={{
          'line-color': '#0A071B',
          'line-width': 2,
          'line-offset': 4,
        }}
        type="line"
      />
      <Layer
        paint={{
          'line-color': '#0A071B',
          'line-width': 2,
          'line-offset': -4,
        }}
        type="line"
      />
    </Source>
  );
};

export default React.memo(BoundariesSource);
