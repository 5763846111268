import { storage } from '../components/State/ConnectionContext';
import fetch from '../utils/fetchWithTimeout';

export default async function fetchPolymathFeedback(): Promise<PolymathFeedback> {
  const baseUrl = storage.getBaseUrl()
  const bearerToken = storage.getBearerToken()
  const deviceKey = storage.getDeviceKey()
  const response = await fetch(`${baseUrl}/polymath-feedback?device_key=${deviceKey}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  }, 2500);
  if (!response.ok) {
    throw Error('Bad response');
  }

  const json: PolymathFeedbackResponse = await response.json();

  if (json.data?.polymath_feedback) {
    return json.data.polymath_feedback;
  }

  throw Error(`Failed to get PolymathFeedback - ${json.status} - ${json.message}`);
};

export type TimeStamp = {
  sec: number
  nanosec: number
}

type Pose = {
  position?: {
    latitude: number
    longitude: number
    altitude?: number
  }
  orientation: {
    x: number
    y: number
    z: number
    w: number
  }
}

type PoseWithId = {
  header?: {}
  pose: Pose
}

export type PolymathFeedback = {
  timestamp_utc: TimeStamp
  current_pose?: PoseWithId
  navigation_feedback?: NavigationFeedback
  vehicle_feedback?: {}
  cortex_status?: CortexStatus
  current_command_index?: number
  number_commands_remaining?: number
};

type CortexStatus = {
  status: number
  status_text: string
  description: string
}

export type NavigationFeedback = {
  current_pose_index: number
  total_distance_remaining: number
  number_of_poses_remaining: number
}

type PolymathFeedbackResponse = {
  data?: {
    polymath_feedback?: PolymathFeedback
  };
  message?: string;
  status: string;
}