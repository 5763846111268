import React, { useCallback, useState } from 'react';
import {
  SlButton,
  SlCard,
  SlDetails,
  // SlDialog,
  SlDrawer,
  SlIconButton,
  SlInput,
  SlOption,
  SlSelect,
  SlSwitch,
} from '@shoelace-style/shoelace/dist/react';
import { useMutation } from '@tanstack/react-query';
import { ExtendedEventTarget } from '../../types/ExtendedEventTarget';
import { MapInteractionType } from '../../types/MapInteractionType';
import postLaunchSimWorld from '../../api/postLaunchSimWorld';
import { publish } from '../../utils/events';
import EventType from '../../types/EventType';
import { getConnectionActionContext, getConnectionStatusContext, storage } from '../State/ConnectionContext';
import { ConnectionStatus } from '../../api/types';

type DrawerProps = {
  displayBoundaries: boolean;
  isOpen: boolean;
  mapInteractionType: MapInteractionType;
  onClose: () => void;
  onDisplayBoundaries: (event: React.MouseEvent) => void;
  onSelectMapInteractionType: (event: Event) => void;
}

const Drawer = ({
  displayBoundaries,
  isOpen,
  mapInteractionType,
  onClose,
  onDisplayBoundaries,
  onSelectMapInteractionType,
}: DrawerProps) => {
  const [bearerTokenInput, setBearerTokenInput] = useState(storage.getBearerToken());
  const [deviceKeyInput, setDeviceKeyInput] = useState(storage.getDeviceKey());
  // const [displayDialog, setDisplayDialog] = useState(false);
  const connection = getConnectionStatusContext()
  const isConnecting = connection === ConnectionStatus.Connecting
  const connect = getConnectionActionContext()

  const postLaunchSimWorldMutation = useMutation({
    mutationFn: () => postLaunchSimWorld(bearerTokenInput, deviceKeyInput, 'farm'),
    onSuccess: (data: string) => {
      if (data.length > 0) {
        publish(EventType.ClearMap);
        postLaunchSimWorldMutation.reset();
      }
    },
  });

  const onClickTrashIcon = useCallback(() => {
    setBearerTokenInput('');
    setDeviceKeyInput('');
  }, []);

  return (
    <SlDrawer
      className="drawer"
      label="Settings"
      onSlAfterHide={(event) => {
        if (event.target
          && event.target satisfies ExtendedEventTarget
          && (event.target as ExtendedEventTarget).localName === 'sl-drawer') {
          onClose();
        }
      }}
      open={isOpen}
    >
      {/* <SlDialog
        noHeader
        onSlRequestClose={(event: Event) => event?.preventDefault()}
        open={displayDialog}
      >
        <b>Resetting simulator instance</b>
        <br />
        <br />
        Please excuse the pop up window while we clean up your simulator world.
        It will close after 60 seconds.
      </SlDialog> */}
      <SlCard>
        <div
          className="card"
          slot="header"
        >
          Connect to a device:
          <SlIconButton
            name="trash"
            onClick={onClickTrashIcon}
          />
        </div>
        <SlInput
          className="input"
          helpText="Large code located in Polymath access email"
          label="Bearer token"
          passwordToggle
          placeholder="Bearer token"
          onSlInput={(event) => {
            if (event.target && event.target satisfies ExtendedEventTarget) {
              setBearerTokenInput((event.target as ExtendedEventTarget).value || '');
            }
          }}
          type="password"
          value={bearerTokenInput}
        />
        <SlInput
          className="input"
          helpText="Short code located in Polymath access email"
          label="Device key"
          passwordToggle
          placeholder="Device key"
          onSlInput={(event) => {
            if (event.target && event.target satisfies ExtendedEventTarget) {
              setDeviceKeyInput((event.target as ExtendedEventTarget).value || '');
            }
          }}
          type="password"
          value={deviceKeyInput}
        />
        <div className="button-group">
          <SlButton
            loading={isConnecting}
            onClick={() => connect(bearerTokenInput, deviceKeyInput)}
            variant="primary"
          >
            Connect
          </SlButton>
        </div>
      </SlCard>
      <SlDetails
        className="details"
        summary="Swap simulator instances"
      >
        You are currently connected using our
        <br />
        test account simulator instance. If you
        <br />
        would like to use your own authorization
        <br />
        credentials, please update the fields
        <br />
        above and click&nbsp;
        <b>Connect</b>
        .
      </SlDetails>
      {/* <SlDetails
        className="details"
        summary="Reset simulator instance"
      >
        It turns out that robotics is hard. And
        <br />
        sometimes the simulator device can get
        <br />
        into a wonky state within the virtual
        <br />
        world.
        <br />
        <br />
        In the event that this happens, click
        <br />
        the&nbsp;
        <b>Relaunch</b>
        &nbsp;button and after 60
        <br />
        seconds you should be able to
        <br />
        operate the simulator device again.
        <br />
        <br />
        <div className="button-group">
          <SlButton
            loading={isConnecting}
            onClick={() => {
              setDisplayDialog(true);
              postLaunchSimWorldMutation.mutate();
              setTimeout(() => setDisplayDialog(false), 60000);
            }}
            variant="danger"
          >
            Relaunch
          </SlButton>
        </div>
      </SlDetails> */}
      <SlCard className="map-settings-card">
        <div slot="header">
          Map settings:
        </div>
        <SlSelect
          label="Click the map to"
          onSlChange={onSelectMapInteractionType}
          value={mapInteractionType}
        >
          <SlOption value={'move' satisfies MapInteractionType}>move the device</SlOption>
          <SlOption value={'waypoint' satisfies MapInteractionType}>set a waypoint</SlOption>
        </SlSelect>
        <SlSwitch
          checked={displayBoundaries}
          className="switch"
          onClick={onDisplayBoundaries}
        >
          Display boundaries
        </SlSwitch>
      </SlCard>
    </SlDrawer>
  );
};

export default React.memo(Drawer);
