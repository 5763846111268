import { storage } from '../components/State/ConnectionContext';
import fetch from '../utils/fetchWithTimeout';

export type UuidData = {
  uuid?: string;
};

type UuidResponse = {
  data?: UuidData;
  message?: string;
  status: string;
}

export default async function fetchUuid(): Promise<UuidData> {
  const baseUrl = storage.getBaseUrl()
  const bearerToken = storage.getBearerToken()
  const deviceKey = storage.getDeviceKey()
  console.log("Fetching UUID")
  const response = await fetch(`${baseUrl}/uuid?device_key=${deviceKey}`, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  }, 2500);
  console.log("UUID response:", response)
  if (!response.ok) {
    throw Error('Bad response');
  }

  const json: UuidResponse = await response.json();
  console.log("UUID response data:", json.data)

  if (json.data?.uuid) {
    return json.data;
  }

  throw Error(`Failed to get UUID - ${json.status} - ${json.message}`);
};

