import React, { useState, createContext, useContext, useEffect } from 'react'
import fetchPolymathFeedback, { TimeStamp } from '../../api/fetchPolymathFeedback'
import { getConnectionStatusContext } from './ConnectionContext'
import { ConnectionStatus } from '../../api/types'
import * as turf from '@turf/turf'
import { Location2D } from '../../utils/GeoPolyline'

export type FeedbackData = {
  position: Location2D | null
  numPosesRemaining: number
  totalDistanceRemaining: number
  cortexStatus: string
}

const PolymathFeedbackInnerContext = createContext<FeedbackData>({
  position: null,
  numPosesRemaining: 0,
  totalDistanceRemaining: 0,
  cortexStatus: '',
})

export const getFeedbackContext: () => FeedbackData = () => {
  return useContext(PolymathFeedbackInnerContext)
}

let isApiCallInProgress = false
const PolymathFeedbackContext: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [timestamp, setTimestamp] = useState<TimeStamp>({ sec: 0, nanosec: 0 })
  const [position, setPosition] = useState<Location2D | null>(null)
  const [numPosesRemaining, setNumPosesRemaining] = useState<number>(0)
  const [totalDistanceRemaining, setTotalDistanceRemaining] = useState<number>(0)
  const [cortexStatus, setCortexStatus] = useState<string>('')
  const connection = getConnectionStatusContext()
  const isConnected = connection === ConnectionStatus.Connected

  useEffect(() => {
    if (!isConnected) return;
    const intervalId = setInterval(() => {
      if (isApiCallInProgress) return;
      isApiCallInProgress = true;
      (async () => {
        try {
          const newFeedback = await fetchPolymathFeedback()

          // Make sure the timestamp is monotonically increasing (otherwise igonre)
          const newTimestamp = newFeedback?.timestamp_utc
          if ((!newTimestamp?.sec || !newTimestamp?.nanosec) &&
            (newTimestamp?.sec < timestamp.sec ||
              (newTimestamp?.sec == timestamp.sec && newTimestamp?.nanosec < timestamp.nanosec))
          ) {
            return
          }
          setTimestamp(newFeedback.timestamp_utc)

          // Update position if it is changed more than 1 foot
          const newPositionRaw = newFeedback.current_pose?.pose?.position ?? null
          const newPosition: Location2D | null = newPositionRaw && { lat: newPositionRaw.latitude, lon: newPositionRaw.longitude }
          const oldPosition = position
          const delta = distanceFt(newPosition ?? nullIsland, oldPosition ?? nullIsland)
          if (delta) {
            if (newPosition?.lat && newPosition?.lon) {
              setPosition(newPosition)
            } else {
              setPosition(null)
            }
          } else {
          }

          // Update other status
          const newNumPosesRemaining = newFeedback?.navigation_feedback?.number_of_poses_remaining
          setNumPosesRemaining(newNumPosesRemaining ?? 0)

          const newTotalDistanceRemaining = newFeedback?.navigation_feedback?.total_distance_remaining ?? 0
          if (Math.abs(newTotalDistanceRemaining - totalDistanceRemaining) > 0.3 /* approx 1 foot */) {
            setTotalDistanceRemaining(newTotalDistanceRemaining)
          }

          setCortexStatus(newFeedback?.cortex_status?.status_text ?? '')

        } catch (error) {
          console.log("polymath feedback error", error)
        } finally {
          isApiCallInProgress = false
        }
      })()
    }, 250)

    return () => clearInterval(intervalId)
  }, [isConnected])

  return (
    <PolymathFeedbackInnerContext.Provider value={{
      position,
      numPosesRemaining,
      totalDistanceRemaining,
      cortexStatus,
    }}>
      {children}
    </PolymathFeedbackInnerContext.Provider>
  )
}

// Function to calculate the distance between two points
function distanceFt(coord1: Location2D, coord2: Location2D): number {
  const from = turf.point([coord1.lon, coord1.lat]);
  const to = turf.point([coord2.lon, coord2.lat]);
  return turf.distance(from, to, { units: 'feet' });
}

const nullIsland = { lon: 0, lat: 0 }

export default PolymathFeedbackContext