import { GeoPolyline, Location2D } from './GeoPolyline';
import * as turf from '@turf/turf'

export type NonEmptyArray<T> = [T, ...T[]];
export type Goals = NonEmptyArray<Location2D>

function generatePresetLineGoals(boundaries: GeoPolyline, position: Location2D): Goals {
  const polygon = turf.polygon([boundaries])
  let pt
  do {
    pt = turf.randomPoint(1, { bbox: turf.bbox(polygon) }).features[0]
  } while (!turf.booleanPointInPolygon(pt, polygon))

  const [lon, lat] = pt.geometry.coordinates
  const point = { lat, lon };

  return [point, position];
};


export default generatePresetLineGoals;
