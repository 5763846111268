export type LoadType = 'simulator' | 'physical'

export function getOnLoad(): LoadType | null {
  const stored = loadFromStorage('load_type')
  if (stored == 'simulator') return 'simulator'
  if (stored == 'physical') return 'physical'
  return null
}
export function getPhysicalBearerToken(): string {
  return loadFromStorage('physical_bearer_token') ?? ''
}
export function getSimulationBearerToken(): string {
  return loadFromStorage('simulation_bearer_token') ?? ''
}
export function getPhysicalDeviceKey(): string {
  return loadFromStorage('physical_device_key') ?? ''
}
export function getSimulationDeviceKey(): string {
  return loadFromStorage('simulation_device_key') ?? ''
}
export function saveOnLoad(value: LoadType) {
  localStorage.setItem('load_type', value)
}
export function savePhysicalBearerToken(value: string) {
  localStorage.setItem('physical_bearer_token', value)
}
export function saveSimulationBearerToken(value: string) {
  localStorage.setItem('simulation_bearer_token', value)
}
export function savePhysicalDeviceKey(value: string) {
  localStorage.setItem('physical_device_key', value)
}
export function saveSimulationDeviceKey(value: string) {
  localStorage.setItem('simulation_device_key', value)
}

function loadFromStorage(field: string): string | null {
  try {
    return localStorage.getItem(field)
  } catch (error) {
    console.log('Failed to get `', field,'` from local storage', error)
    return null
  }
}