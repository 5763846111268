import { v4 as uuidv4 } from 'uuid';
import calculateYaw from './calculateYaw';
import { Location2D } from './GeoPolyline';
import { Goals, NonEmptyArray } from './generatePresetLineGoals';

function mapGoals(markers: NonEmptyArray<Location2D>, position: Location2D): Goals {
  return markers.map((marker, index, array) => {
    let yaw = 0.0;
    switch (index) {
      case 0:
        yaw = calculateYaw({
          id: uuidv4(),
          lat: position.lat,
          lon: position.lon,
        }, {
          id: uuidv4(),
          lat: marker.lat,
          lon: marker.lon,
        });
        break;
      default:
        yaw = calculateYaw({
          id: uuidv4(),
          lat: array[index - 1].lat,
          lon: array[index - 1].lon,
        }, {
          id: uuidv4(),
          lat: marker.lat,
          lon: marker.lon,
        });
        break;
    }
    return {
      lat: marker.lat,
      lon: marker.lon,
      yaw,
    };
  }) as Goals;  // Make sure the input is constrained to 1 or more element, or this cast will be safe
}

export default mapGoals;
