import React, { useCallback, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import Header from '../Header';
import Drawer from '../Drawer';
import { MapInteractionType } from '../../types/MapInteractionType';
import { ExtendedEventTarget } from '../../types/ExtendedEventTarget';
import postWaypoints from '../../api/postWaypoints';
import Hud from '../Hud';
import Map from '../Map';
import { getFeedbackContext } from '../State/PolymathFeedbackContext';
import { storage } from '../State/ConnectionContext';
import { Goals } from '../../utils/generatePresetLineGoals';

const Layout = () => {
  const feedback = getFeedbackContext()
  const position = feedback.position
  const [displayBoundaries, setDisplayBoundaries] = useState(true);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);
  const [mapInteractionType, setMapInteractionType] = useState<MapInteractionType>('move');

  const postCancelPreviousGoalMutation = useMutation({
    mutationFn: () => {
      if (!position) throw Error('Excepted the vehicle position to be available')
      return postWaypoints([{ lat: position?.lat, lon: position?.lon }], 'PREEMPT')
    },
  });

  const postWaypointsMutation = useMutation({
    mutationFn: (
      variables: { goals: Goals, withinBoundaries: boolean },
    ) => postWaypoints(
      variables.goals,
      'PREEMPT',
    ),
  });

  const onDrawerSelectMapInteractionType = useCallback((event: Event) => {
    if (event.target && event.target satisfies ExtendedEventTarget) {
      setMapInteractionType(((event.target as ExtendedEventTarget).value as MapInteractionType) || 'move');
    }
  }, []);

  return (
    <div className="layout">
      <Drawer
        displayBoundaries={displayBoundaries}
        isOpen={drawerIsOpen}
        mapInteractionType={mapInteractionType}
        onClose={() => setDrawerIsOpen(false)}
        onDisplayBoundaries={(event: React.MouseEvent) => {
          event.preventDefault();
          setDisplayBoundaries(!displayBoundaries);
        }}
        onSelectMapInteractionType={onDrawerSelectMapInteractionType}
      />
      <Header onGear={() => setDrawerIsOpen(true)} />
      <Hud
        mapInteractionType={mapInteractionType}
        postCancelPreviousGoalMutation={postCancelPreviousGoalMutation}
        postWaypointsMutation={postWaypointsMutation}
      />
      <Map
        bearerToken={storage.getBearerToken()}
        boundaries={storage.getBoundary()}
        deviceKey={storage.getDeviceKey()}
        displayBoundaries={displayBoundaries}
        mapInteractionType={mapInteractionType}
        postCancelPreviousGoalMutation={postCancelPreviousGoalMutation}
        postWaypointsMutation={postWaypointsMutation}
      />
    </div>
  );
};

export default React.memo(Layout);
