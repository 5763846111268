import { GeoPolyline } from "../utils/GeoPolyline"
import boundary from "./boundary"
import {
  LoadType,
  getOnLoad,
  getPhysicalBearerToken,
  getPhysicalDeviceKey,
  getSimulationBearerToken,
  getSimulationDeviceKey,
  saveOnLoad,
  savePhysicalBearerToken,
  savePhysicalDeviceKey,
  saveSimulationBearerToken,
  saveSimulationDeviceKey
} from "./localStorage"

export default class StoredInfo {
  private type: LoadType = getOnLoad() ?? 'simulator'
  private physicalBearerToken: string = getPhysicalBearerToken()
  private simulationBearerToken: string = getSimulationBearerToken()
  private physicalDeviceKey: string = getPhysicalDeviceKey()
  private simulationDeviceKey: string = getSimulationDeviceKey()

  getBaseUrl = () => 'https://synapse.api.polymathrobotics.dev/v2'
  getType(): LoadType { return this.type }
  setType(type: LoadType) {
    this.type = type
    saveOnLoad(type)
  }
  getBearerToken(): string {
    if (this.type == 'physical') return this.physicalBearerToken
    return this.simulationBearerToken
  }
  getDeviceKey(): string {
    if (this.type == 'physical') return this.physicalDeviceKey
    return this.simulationDeviceKey
  }
  setBearerToken(token: string) {
    if (this.type == 'physical') {
      this.physicalBearerToken = token
      savePhysicalBearerToken(token)
    }
    this.simulationBearerToken = token
    saveSimulationBearerToken(token)
  }
  setDeviceKey(token: string) {
    if (this.type == 'physical') {
      this.physicalDeviceKey = token
      savePhysicalDeviceKey(token)
    }
    this.simulationDeviceKey = token
    saveSimulationDeviceKey(token)
  }
  getBoundary(): GeoPolyline {
    return boundary
  }
}

