import { UseMutationResult } from '@tanstack/react-query';
import React from 'react';
import { PostWaypointsMutationVariables } from '../../types/PostWaypointsMutationVariables';
import { MapInteractionType } from '../../types/MapInteractionType';
import { getConnectionStatusContext, getUuidContext } from '../State/ConnectionContext';
import { ConnectionStatus } from '../../api/types';
import { getFeedbackContext } from '../State/PolymathFeedbackContext';

type HudProps = {
  mapInteractionType: MapInteractionType;
  postCancelPreviousGoalMutation: UseMutationResult<string, unknown, void, unknown>;
  postWaypointsMutation: UseMutationResult<
    string,
    unknown,
    PostWaypointsMutationVariables,
    unknown
  >;
}

const Hud = ({
  mapInteractionType,
  postCancelPreviousGoalMutation,
  postWaypointsMutation,
}: HudProps) => {
  const connection = getConnectionStatusContext()
  const failed = connection === ConnectionStatus.Failed
  const connecting = connection === ConnectionStatus.Connecting
  const connected = connection === ConnectionStatus.Connected
  const uuid = getUuidContext()
  const feedback = getFeedbackContext()
  const status = feedback.cortexStatus

  return (
    <div className="hud u-background-color-primary">
      {connecting && (
        <div className="uuid">
          Connecting to device...
        </div>
      )}
      {(connected
        && postWaypointsMutation.isIdle
        && postCancelPreviousGoalMutation.isIdle) && (
          <div className="uuid">
            You are connected to device:&nbsp;
            {uuid}
          </div>
        )}
      {(postWaypointsMutation.isError
        && postWaypointsMutation.variables?.withinBoundaries === false) && (
          <div className="uuid">
            Gps goals must be within the depicted boundaries.
          </div>
        )}
      {(mapInteractionType === 'move') ? (
        <>
          {(postWaypointsMutation.isError
            && postWaypointsMutation.variables?.withinBoundaries === true) && (
              <div className="uuid">
                Failed to send gps goal to device. Please try again.
              </div>
            )}
          {(postWaypointsMutation.isLoading) && (
            <div className="uuid">
              Sending goal of [
              {postWaypointsMutation.variables?.goals[0].lat}
              ,&nbsp;
              {postWaypointsMutation.variables?.goals[0].lon}
              ,&nbsp;
              {postWaypointsMutation.variables?.goals[0].yaw}
              ] to device.
            </div>
          )}
          {postWaypointsMutation.isSuccess && (
            <div className="uuid">
              Successfully sent goal of [
              {postWaypointsMutation.variables?.goals[0].lat}
              ,&nbsp;
              {postWaypointsMutation.variables?.goals[0].lon}
              ,&nbsp;
              {postWaypointsMutation.variables?.goals[0].yaw}
              ] to device.
            </div>
          )}
        </>
      ) : (
        <>
          {(postWaypointsMutation.isError
            && postWaypointsMutation.variables?.withinBoundaries === true) && (
              <div className="uuid">
                Failed to send gps goals to device. Please try again.
              </div>
            )}
          {(postWaypointsMutation.isLoading) && (
            <div className="uuid">
              Sending&nbsp;
              {postWaypointsMutation.variables?.goals.length}
              &nbsp;goals to device.
            </div>
          )}
          {postWaypointsMutation.isSuccess && (
            <div className="uuid">
              Successfully sent&nbsp;
              {postWaypointsMutation.variables?.goals.length}
              &nbsp;goals to device.
            </div>
          )}
        </>
      )}
      {postCancelPreviousGoalMutation.isError && (
        <div className="uuid">
          Failed to cancel previous gps goal.
        </div>
      )}
      {postCancelPreviousGoalMutation.isLoading && (
        <div className="uuid">
          Canceling previous gps goal.
        </div>
      )}
      {postCancelPreviousGoalMutation.isSuccess && (
        <div className="uuid">
          Successfully canceled previous gps goal.
        </div>
      )}
      {status === 'STOPPED' && (
        <div className="warn">
          Vehicle autonomy has been stopped; please click "Resume Autonomy" to resume
        </div>
      )}
      {failed && (
        <div className="error">
          Failed to connect to a device. Please check your bearer token
          and device key.
        </div>
      )}
    </div>
  )
}

export default React.memo(Hud);
