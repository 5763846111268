import React from 'react';
import { Layer, Source } from 'react-map-gl';
import { Marker } from '../../types/Marker';

type RouteMarkersSourceProps = {
  routeMarkers: Marker[];
};

const RouteMarkersSource = ({
  routeMarkers,
}: RouteMarkersSourceProps) => {
  const data: GeoJSON.FeatureCollection<GeoJSON.LineString> = {
    features: [{
      geometry: {
        coordinates: routeMarkers.map(
          (routeMarker) => [routeMarker.lon, routeMarker.lat],
        ),
        type: 'LineString',
      },
      properties: {},
      type: 'Feature',
    }],
    type: 'FeatureCollection',
  };

  return (
    <Source
      type="geojson"
      data={data}
    >
      <Layer
        paint={{
          'line-color': '#D1EE78',
          'line-width': 7,
          'line-offset': 0,
        }}
        type="line"
      />
    </Source>
  );
};

export default React.memo(RouteMarkersSource);
