import React, { useCallback, useState } from 'react';
import { SlButton } from '@shoelace-style/shoelace/dist/react';
import { UseMutationResult } from '@tanstack/react-query';
import { useMap } from 'react-map-gl';
import { PostWaypointsMutationVariables } from '../../types/PostWaypointsMutationVariables';
import { MapInteractionType } from '../../types/MapInteractionType';
import { Marker as PolyMarker } from '../../types/Marker';
import mapGoals from '../../utils/mapGoals';
import { getConnectionStatusContext } from '../State/ConnectionContext';
import { getFeedbackContext } from '../State/PolymathFeedbackContext';
import { ConnectionStatus } from '../../api/types';
import { GeoPolyline, polylineCenter } from '../../utils/GeoPolyline';
import { NonEmptyArray } from '../../utils/generatePresetLineGoals';
import { zoom } from '../../appConfig';
import postMotionCommand from '../../api/postMotionCommand';

type ControlsProps = {
  boundaries: GeoPolyline;
  goalMarker: PolyMarker | undefined;
  isCompletingGoals: boolean;
  mapInteractionType: MapInteractionType;
  onClearMap: () => void;
  onPresetLine: () => void;
  onSendGoals: () => void;
  positionMarkers: PolyMarker[];
  postCancelPreviousGoalMutation: UseMutationResult<string, unknown, void, unknown>;
  postWaypointsMutation: UseMutationResult<
    string,
    unknown,
    PostWaypointsMutationVariables,
    unknown
  >;
  startPosition: PolyMarker | undefined;
  waypointMarkers: PolyMarker[];
  }

const Controls = ({
  boundaries,
  goalMarker,
  isCompletingGoals,
  mapInteractionType,
  onClearMap,
  onPresetLine,
  onSendGoals,
  positionMarkers,
  postCancelPreviousGoalMutation,
  postWaypointsMutation,
  startPosition,
  waypointMarkers,
  }: ControlsProps): JSX.Element => {
  const feedback = getFeedbackContext()
  const position = feedback.position
  const status = feedback.cortexStatus
  const connection = getConnectionStatusContext()
  const isConnected = connection === ConnectionStatus.Connected
  const isConnecting = connection === ConnectionStatus.Connecting
  const { PolyMap } = useMap();
  const [isCallingResume, setIsCallingResume] = useState<boolean>(false)

  const isDisabledPresetReplay = useCallback(() => {
    if (isConnecting) return true;
    switch (mapInteractionType) {
      case 'move':
        if (positionMarkers.length > 0 && postWaypointsMutation.isSuccess) return false;
        break;
      default:
        if (waypointMarkers.length > 0 && postWaypointsMutation.isSuccess) return false;
        break;
    }
    return true;
  }, [
    mapInteractionType,
    positionMarkers,
    postWaypointsMutation.isSuccess,
    isConnecting,
    waypointMarkers,
  ]);

  const isDisabledCancelPreviousGoal = useCallback(() => isConnecting
  || !isCompletingGoals, [
    isCompletingGoals,
    isConnecting,
  ]);

  const onCancelPreviousGoal = useCallback(() => {
    postWaypointsMutation.reset();
    postCancelPreviousGoalMutation.mutate();
  }, [postCancelPreviousGoalMutation, postWaypointsMutation]);

  const onFocusOnDevice = useCallback(() => {
    const devicePosition = position ? {
      latitude: position.lat,
      longitude: position.lon,
    } : undefined;

    if (devicePosition) {
      PolyMap?.flyTo({
        center: [devicePosition.longitude, devicePosition.latitude],
        zoom,
      });
    }
  }, [PolyMap, position]);

  const onFocusOnBoundaries = () => {
    const center = polylineCenter(boundaries)
    center && PolyMap?.flyTo({ center, zoom });
  };

  const onPresetReplay = useCallback(() => {
    if (!position) return;
    postCancelPreviousGoalMutation.reset();
    let markers = [];
    switch (mapInteractionType) {
      case 'move':
        markers = goalMarker ? positionMarkers.concat(goalMarker) : positionMarkers;
        break;
      default:
        markers = startPosition ? [startPosition].concat(waypointMarkers) : waypointMarkers;
        break;
    }
    if (markers.length !== 0) {
      const goals = mapGoals(markers as NonEmptyArray<PolyMarker>, position);
      postWaypointsMutation.mutate({
        goals,
        withinBoundaries: true,
      });
    }
  }, [
    goalMarker,
    mapInteractionType,
    positionMarkers,
    position,
    postCancelPreviousGoalMutation,
    postWaypointsMutation,
    startPosition,
    waypointMarkers,
  ]);

  const onCallResume = () => {
    setIsCallingResume(true)
    postMotionCommand('RESUME').finally(() => {
      setIsCallingResume(false)
    })
  }

  return (
    <div className="controls u-background-color-primary">
      <div className="button-group">
        <SlButton
          className="button"
          disabled={!isConnected}
          loading={isConnecting}
          onClick={onFocusOnDevice}
          variant="primary"
        >
          Focus on device
        </SlButton>
        <SlButton
          className="button"
          disabled={!isConnected}
          loading={isConnecting}
          onClick={onFocusOnBoundaries}
          variant="primary"
        >
          Focus on boundaries
        </SlButton>
        <SlButton
          className="button"
          disabled={!isConnected}
          loading={isConnecting}
          onClick={onPresetLine}
          variant="success"
        >
          Preset: line
        </SlButton>
        <SlButton
          className="button"
          disabled={isDisabledPresetReplay()}
          loading={isConnecting}
          onClick={onPresetReplay}
          variant="success"
        >
          Preset: replay
        </SlButton>
        { status === 'STOPPED' && (
          <SlButton
            className="button"
            loading={isCallingResume}
            onClick={onCallResume}
            variant="warning"
          >
            Resume Autonomy
          </SlButton>
        ) }
        { mapInteractionType === 'move' && (
          <SlButton
            className="button"
            disabled={isDisabledCancelPreviousGoal()}
            loading={isConnecting}
            onClick={onCancelPreviousGoal}
            variant="danger"
          >
            Cancel previous goal
          </SlButton>
        ) }
        { mapInteractionType === 'waypoint' && (
          <SlButton
            className="button"
            disabled={!isConnected}
            loading={isConnecting}
            onClick={onSendGoals}
            variant="success"
          >
            Send goals
          </SlButton>
        ) }
        { mapInteractionType === 'waypoint' && (
          <SlButton
            className="button"
            disabled={isDisabledCancelPreviousGoal()}
            loading={isConnecting}
            onClick={onClearMap}
            variant="danger"
          >
            Cancel goals
          </SlButton>
        ) }
        <SlButton
          className="button"
          disabled={!isConnected}
          loading={isConnecting}
          onClick={onClearMap}
          variant="danger"
        >
          Clear map
        </SlButton>
      </div>
    </div>
  );
};

export default React.memo(Controls);
