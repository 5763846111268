import React from 'react';
import PolymathLogo from '../../assets/polymathLogo.png';

const Logo = (): JSX.Element | null => {
  return (
    <div className="logo">
      <img
        alt="Polymath Robotics"
        src={PolymathLogo}
      />
    </div>
  );
};

export default React.memo(Logo);
