import fetch from '../utils/fetchWithTimeout';

type SimWorldResponse = {
  data?: string;
  message?: string;
  status: string;
}

type SimWorldType = 'farm' | 'mine' | 'port';

const postLaunchSimWorld = async (
  bearerToken: string,
  deviceKey: string,
  simWorldType: SimWorldType,
) => {
  if (!bearerToken || !deviceKey) {
    throw Error('Missing bearer token or device key');
  }

  const body = {
    simulation_world: simWorldType,
  };
  const encodedBody = Object.entries(body).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');

  const response = await fetch(`https://beta-caladan.polymathrobotics.dev/api/launch-sim-world?device_key=${deviceKey}`, {
    body: encodedBody,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
  });

  if (!response.ok) {
    throw Error('Bad response');
  }

  const json: SimWorldResponse = await response.json();

  if (json.status === 'success' && json.data) {
    return json.data;
  }

  throw Error(`Bad status - ${json.status} - ${json.message}`);
};

export default postLaunchSimWorld;
