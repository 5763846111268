import React from 'react';
import { createRoot } from 'react-dom/client';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';
import Application from './components/Application';

import './styles.scss';

setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.3.0/dist/');

window.addEventListener('load', () => {
  const container = document.querySelector('.root');
  if (!container) return 'Error starting application';
  const root = createRoot(container);
  // Render the React application to the DOM
  return root.render(<Application />);
});
