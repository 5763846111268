import * as turf from '@turf/turf';
import { NonEmptyArray } from './generatePresetLineGoals';

export type Location2D = {
  lon: number
  lat: number
  yaw?: number
}

export type Coord = [lon: number, lat: number]
export type GeoPolyline = NonEmptyArray<Coord>  // at least 1 item

// Returns the center point of a polyline's extents
export function polylineCenter(polyline: GeoPolyline): Location2D {
  let minLon = 1000
  let maxLon = -1000
  let minLat = 1000
  let maxLat = -1000
  for (const coord of polyline) {
    minLon = Math.min(minLon, coord[0])
    maxLon = Math.max(maxLon, coord[0])
    minLat = Math.min(minLat, coord[1])
    maxLat = Math.max(maxLat, coord[1])
  }
  return {
    lon: minLon + (maxLon - minLon) / 2,
    lat: minLat + (maxLat - minLat) / 2,
  }
}

// Function to check if a point is within the polygon formed by the polyline
export function isPointInPolyline(location: Location2D, polyline: GeoPolyline): boolean {
  const polygon = turf.polygon([polyline.concat([polyline[0]])]);
  const point = turf.point([location.lon, location.lat]);
  return turf.booleanPointInPolygon(point, polygon);
}