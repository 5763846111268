const fetchWithTimeout = async (
  input: RequestInfo | URL,
  init?: RequestInit | undefined,
  timeout = 2500,
) => {
  // Create a signal with timeout
  const signal = AbortSignal.timeout(timeout);

  // Make the fetch request
  const fetchWithTimeout = fetch(input, {
    ...init,
    signal,
  });

  // Await the fetch with a catch in case it's aborted which signals an error
  const result = await fetchWithTimeout;
  return result;
};

export default fetchWithTimeout;
