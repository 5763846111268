import React, { useCallback, useEffect, useState } from 'react';
import { UseMutationResult } from '@tanstack/react-query';
import Map, { Marker, useMap } from 'react-map-gl';
import { v4 as uuidv4 } from 'uuid';
import { Marker as PolyMarker } from '../../types/Marker';
import { PostWaypointsMutationVariables } from '../../types/PostWaypointsMutationVariables';
import BoundariesSource from './BoundariesSource';
import RouteMarkersSource from './RouteMarkersSource';
import { MapInteractionType } from '../../types/MapInteractionType';
import Controls from '../Controls';
import { subscribe, unsubscribe } from '../../utils/events';
import EventType from '../../types/EventType';
import calculateYaw from '../../utils/calculateYaw';
import mapGoals from '../../utils/mapGoals';
import usePrevious from '../../hooks/usePrevious';
import generatePresetLineGoals, { NonEmptyArray } from '../../utils/generatePresetLineGoals';
import { getConnectionStatusContext, storage } from '../State/ConnectionContext';
import { getFeedbackContext } from '../State/PolymathFeedbackContext';
import { ConnectionStatus } from '../../api/types';
import { GeoPolyline, isPointInPolyline, polylineCenter } from '../../utils/GeoPolyline';
import { SHOULD_CHECK_BOUNDARY, zoom } from '../../appConfig';

type PolyMapProps = {
  bearerToken: string;
  boundaries: GeoPolyline;
  deviceKey: string;
  displayBoundaries: boolean;
  mapInteractionType: MapInteractionType;
  postCancelPreviousGoalMutation: UseMutationResult<string, unknown, void, unknown>;
  postWaypointsMutation: UseMutationResult<
    string,
    unknown,
    PostWaypointsMutationVariables,
    unknown
  >;
}

const PolyMap = ({
  boundaries,
  displayBoundaries,
  mapInteractionType,
  postCancelPreviousGoalMutation,
  postWaypointsMutation,
}: PolyMapProps): JSX.Element => {
  const [goalMarker, setGoalMarker] = useState<PolyMarker | undefined>(undefined);
  const [positionMarkers, setPositionMarkers] = useState<PolyMarker[]>([]);
  const [routeMarkers, setRouteMarkers] = useState<PolyMarker[]>([]);
  const [startPosition, setStartPosition] = useState<PolyMarker | undefined>(undefined);
  const [waypointMarkers, setWaypointMarkers] = useState<PolyMarker[]>([]);
  const [isCompletingGoals, setIsCompletingGoals] = useState(false);
  const { PolyMap } = useMap();
  const previousMapInteractionType = usePrevious(mapInteractionType);

  const connection = getConnectionStatusContext()
  const connected = connection === ConnectionStatus.Connected
  const feedback = getFeedbackContext()
  const position = feedback.position
  const numPosesRemaining = feedback.numPosesRemaining
  const totalDistanceRemaining = feedback.totalDistanceRemaining

  useEffect(() => {
    if(boundaries && startPosition) {
      console.log("boundaries", boundaries, startPosition)

      const center = polylineCenter(boundaries)
      console.log("center", center)
      PolyMap?.flyTo({ center, zoom });
    }
  }, [boundaries, PolyMap, startPosition]);

  useEffect(() => {
    if (postWaypointsMutation.variables?.withinBoundaries === true
      && postWaypointsMutation.isSuccess === true) {
      setIsCompletingGoals(true);
    }
  }, [postWaypointsMutation.isSuccess, postWaypointsMutation.variables]);

  const previousPositionQueryData = usePrevious(position);

  useEffect(() => {
    if (position === previousPositionQueryData) return;
    if (!position) return;

    if (!startPosition) {
      setStartPosition({
        id: uuidv4(),
        lat: position?.lat,
        lon: position?.lon,
      });
      const updatedPositionMarkers = positionMarkers.concat({
        id: uuidv4(),
        lat: position?.lat,
        lon: position?.lon,
      });
      setPositionMarkers(updatedPositionMarkers);
    } else {
      const updatedRouteMarkers = routeMarkers.concat({
        id: uuidv4(),
        lat: position?.lat,
        lon: position?.lon,
      });
      setRouteMarkers(updatedRouteMarkers);
    }
  }, [positionMarkers, position, previousPositionQueryData, routeMarkers, startPosition]);


  useEffect(() => {
    if (numPosesRemaining == 0 || totalDistanceRemaining < 2) {
      setIsCompletingGoals(false);
    }
  }, [numPosesRemaining, totalDistanceRemaining]);

  const onClearMap = () => {
    setGoalMarker(undefined);
    setIsCompletingGoals(false);
    setPositionMarkers([]);
    setRouteMarkers([]);
    setStartPosition(undefined);
    setWaypointMarkers([]);
    postWaypointsMutation.reset();
    postCancelPreviousGoalMutation.mutate();
    postCancelPreviousGoalMutation.reset();
  };

  useEffect(() => {
    subscribe(EventType.ClearMap, onClearMap);

    return () => {
      unsubscribe(EventType.ClearMap, onClearMap);
    };
  }, [onClearMap]);


  useEffect(() => {
    if (!connected) return;
    if (mapInteractionType !== previousMapInteractionType) {
      onClearMap();
    }
  }, [mapInteractionType, onClearMap, previousMapInteractionType, connected]);

  const onPresetLine = () => {
    if (!position) return;
    postCancelPreviousGoalMutation.reset();
    const goals = generatePresetLineGoals(boundaries, position);
    let updatedPositionMarkers = [];
    let updatedWaypointMarkers = [];
    switch (mapInteractionType) {
      case 'move':
        updatedPositionMarkers = positionMarkers.concat({
          id: uuidv4(),
          lat: goals[1].lat,
          lon: goals[1].lon,
        });
        setPositionMarkers(updatedPositionMarkers);
        setGoalMarker({
          id: uuidv4(),
          lat: goals[0].lat,
          lon: goals[0].lon,
        });
        break;
      default:
        updatedWaypointMarkers = waypointMarkers.concat({
          id: uuidv4(),
          lat: goals[0].lat,
          lon: goals[0].lon,
        });
        setWaypointMarkers(updatedWaypointMarkers);
        break;
    }
    postWaypointsMutation.mutate({
      goals: mapGoals(goals, position),
      withinBoundaries: true,
    });
  };

  const onSendGoals = () => {
    if (!position) return;
    postCancelPreviousGoalMutation.reset();
    if(waypointMarkers.length !== 0) {
      postWaypointsMutation.mutate({
        goals: mapGoals(waypointMarkers as NonEmptyArray<PolyMarker>, position),
        withinBoundaries: true,
      });
    }
  };

  const onMapClick = useCallback((event: mapboxgl.MapLayerMouseEvent) => {
    if (event.lngLat && 
      (!SHOULD_CHECK_BOUNDARY || isPointInPolyline({lat: event.lngLat.lat, lon: event.lngLat.lng}, storage.getBoundary()))) {
      let updatedWaypointMarkers = [];
      switch (mapInteractionType) {
        case 'move':
          postCancelPreviousGoalMutation.reset();
          postWaypointsMutation.mutate({
            goals: [{
              lat: event.lngLat.lat,
              lon: event.lngLat.lng,
              yaw: position ? calculateYaw(
                {
                  id: uuidv4(),
                  lat: position?.lat,
                  lon: position?.lon,
                },
                {
                  id: uuidv4(),
                  lat: event.lngLat.lat,
                  lon: event.lngLat.lng,
                },
              ) : 0.0,
            }],
            withinBoundaries: true,
          });
          if (goalMarker !== undefined && position) {
            const updatedPositionMarkers = positionMarkers.concat({
              id: uuidv4(),
              lat: position.lat,
              lon: position.lon,
            });
            setPositionMarkers(updatedPositionMarkers);
          }
          setGoalMarker({ id: uuidv4(), lat: event.lngLat.lat, lon: event.lngLat.lng });
          break;
        default:
          updatedWaypointMarkers = waypointMarkers.concat({
            id: uuidv4(),
            lat: event.lngLat.lat,
            lon: event.lngLat.lng,
          });
          setWaypointMarkers(updatedWaypointMarkers);
          break;
      }
    } else {
      switch (mapInteractionType) {
        case 'waypoint':
          setTimeout(postWaypointsMutation.reset, 1500);
          break;
        default:
          break;
      }
    }
  }, [
    boundaries,
    goalMarker,
    mapInteractionType,
    positionMarkers,
    position,
    postCancelPreviousGoalMutation,
    postWaypointsMutation,
    waypointMarkers,
  ]);

  return (
    <div className="map u-background-color-primary">
      <Map
        mapboxAccessToken="pk.eyJ1IjoiY2hyaXNkaGFhbiIsImEiOiJjbGZ2dmRobHAwNGx0M3JvYXA5ZzRoODlrIn0.aiLXUWdwB0mBJr7ulDZuSA"
        boxZoom={false}
        doubleClickZoom={false}
        dragPan={true}
        dragRotate={false}
        id="PolyMap"
        initialViewState={{
          latitude: 42.877742,
          longitude: -97.380979,
          zoom: 3,
        }}
        keyboard={false}
        mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
        onClick={onMapClick}
        scrollZoom={true}
        touchPitch={false}
        touchZoomRotate={false}
      >
        {(positionMarkers.length > 0) && (
          positionMarkers.map((positionMarker) => (
            <Marker
              color="#5138EE"
              key={`${positionMarker.id}-${positionMarker.lat}-${positionMarker.lon}`}
              latitude={positionMarker.lat}
              longitude={positionMarker.lon}
            />
          ))
        )}
        {(goalMarker !== undefined) && (
          <Marker
            color="#0A071B"
            latitude={goalMarker.lat}
            longitude={goalMarker.lon}
          />
        )}
        {(waypointMarkers.length > 0) && (
          waypointMarkers.map((waypointMarker) => (
            <Marker
              color="#0A071B"
              key={`${waypointMarker.lat}-${waypointMarker.lon}`}
              latitude={waypointMarker.lat}
              longitude={waypointMarker.lon}
            />
          ))
        )}
        {(routeMarkers.length > 0) && (
          <RouteMarkersSource routeMarkers={routeMarkers} />
        )}
        {(displayBoundaries === true) && (
          <BoundariesSource boundaries={boundaries} />
        )}
      </Map>
      <Controls
        boundaries={boundaries}
        goalMarker={goalMarker}
        isCompletingGoals={isCompletingGoals}
        mapInteractionType={mapInteractionType}
        onClearMap={onClearMap}
        onPresetLine={onPresetLine}
        onSendGoals={onSendGoals}
        positionMarkers={positionMarkers}
        postCancelPreviousGoalMutation={postCancelPreviousGoalMutation}
        postWaypointsMutation={postWaypointsMutation}
        startPosition={startPosition}
        waypointMarkers={waypointMarkers}
      />
    </div>
  );
};

export default React.memo(PolyMap);
